<template>
  <Content :search="false">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <h2>Rēķins</h2>
      </div>
    </template>

    <template v-slot:content>
      <div>
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <Transition>
              <template v-if="invoice && showData">
                <InvoiceDetails :item="invoice" />
              </template>
            </Transition>

            <Transition>
              <template v-if="invoice && invoice.customer && showData">
                <AllInvoiceOrderItems
                  :invoice="invoice"
                />
              </template>
            </Transition>

          </ul>
        </div>
      </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from "vuex";
import { defineAsyncComponent } from 'vue'

const InvoiceDetails = defineAsyncComponent(() =>
  import('@/components/Invoices/Invoice/InvoiceDetails'))
const AllInvoiceOrderItems = defineAsyncComponent(() =>
  import('@/components/Invoices/Invoice/AllInvoiceOrderItems'))

export default {
  name: "Invoice",
  components: {
    InvoiceDetails,
    AllInvoiceOrderItems,
  },
  data() {
    return {
      showData: false,
    }
  },
  created() {
    this.$store.dispatch("getSingleInvoice", this.$route.params.orderId).then(() => {
      this.showData = true
    })
  },
  computed: {
    ...mapGetters({
      invoice: "invoice",
    }),
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>